@import 'chat';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ysabeau:ital,wght@0,1..1000;1,1..1000&display=swap');
@import './assets/icomoon/style.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  background: black;
  font-family: 'Open Sans', 'Arial', sans-serif;
  -ms-overflow-style: scrollbar;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(#8a8262, 0.5);
    box-shadow: 0 0 1px #000000;
  }
}

.content-wrapper {
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 15px;
}

@media only screen and (max-width: 991px) {
  .content-wrapper {
    max-width: 85%;
  }
}

:root {
  --color-text: #fff;
  --color-text-dark: #747481;
  --color-text-yellow: #f9e31a;
  --color-text-light-purple: #595967;
  --color-highlight-text: #8a8262;
  --bg-color: #19191a;
  --color-border-gray: #272729;
  --color-active: #776bf8;
  --color-active-dark: #4d43b7;

  --white-color: #ffffff;
  --gray-color: rgb(219, 223, 230);
  --dark-gray-color: #888888;

  --gray-gradient: linear-gradient(135deg, rgb(41, 50, 60) 0%, rgb(72, 85, 99) 100%);

  --dark-box-shadow: rgb(0 18 46 / 24%) 0px 6px 32px 0px;
  --gray-box-shadow: rgb(41 50 60 / 50%) 0px 2px 16px;

  --dark-blue-transparent: rgba(0, 23, 59, 0.32);
  --white-background: #ffffff;
  --blue-background: rgb(0, 125, 252);
  --dark-blue-background: rgb(0, 23, 59);
  --gray-blue-background: rgba(0, 36, 92, 0.16);
  --light-white-background: rgb(240, 242, 247);

  --modal-bg: #141416;
  --card-bg: #17171c;

  --input-height: 40px;
  --input-color: #fff;
  --input-bg: #1a1a1e;
  --input-bg-active: #222228;
  --input-error: #821718;
  --input-error-bg: #26262e;
  --input-error-color: #fff;

  --btn-main-color: #fff;
  --btn-main-bg: var(--color-active);
  --btn-main-border: var(--color-active);
}

::ng-deep {
  .modal-content {
    border: none;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.toast-custom-solana {
  margin-top: 8px;
  line-height: 20px;

  a {
    display: block;
    margin-top: 8px;
    color: var(--color-active-dark) !important;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
  }
}

#toast-container .ngx-toastr {
  padding: 15px 20px;
  background-image: none !important;
}

@media (max-width: 574px) {
  .modal-dialog.modal-dialog-centered {
    margin: 2rem;
  }
}

.spinner {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 150px auto;
  opacity: 0.5;

  .ripple-loader {
    position: relative;
    width: 64px;
    height: 64px;

    div {
      position: absolute;
      border: 4px solid #fff;
      border-radius: 50%;
      animation: ripple-loader 1s ease-out infinite;

      &:nth-child(2) {
        animation-delay: -0.5s;
        align-items: center;
      }
    }
  }
}

@keyframes ripple-loader {
  0% {
    top: 32px;
    left: 32px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 64px;
    height: 64px;
    opacity: 0;
  }
}
